import s from "styled-components";

import Box from "./box";

export default function Content() {
  return (
    <Box>
      <Title>This Publit website is coming soon.</Title>
      <Description>
        Publit is a premium website builder for professionals, phd candidates,
        writers, academics, journalists, designers, and students.
      </Description>
      <ButtonLink href="https://publit.app" target="_blank">
        Create My Own Website
      </ButtonLink>
      <Description>
        <Link
          href="https://publit.app"
          rel="noopener noreferrer"
          target="_blank"
        >
          www.publit.app
        </Link>
      </Description>
    </Box>
  );
}

const Title = s.h2`
margin: 0;
margin-top: 40px;
color: #007FF5;
font-family: Manrope;
font-weight: bold;
font-size: 24px;
line-height: 33px;
`;

const Description = s.p`
margin: 40px 0;
font-size: 20px;
line-height: 27px;
`;

const ButtonLink = s.a`
position: relative;
z-index: 1;

display: inline-block;
padding: 10px 24px;
margin: 10px 0;

font-family: Manrope;
font-weight: bold;
font-size: 18px;
line-height: 25px;
text-decoration: none;

border-radius: 9999px;

box-shadow: 0 0 0 10px rgb(0 133 255 / 20%);
color: white;
border: none;
background: white;

transition: box-shadow 0.25s ease-in-out, color 0.25s ease-in-out;

&::before {
  content: "";

  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  transition: opacity 0.25s ease-in-out;
  border-radius: 9999px;
  background: linear-gradient(180deg, #1FACFF 0%, #007FF5 100%);
}

&:hover {
  color: #007FF5;
  box-shadow: 0 0 0 2px #007FF5, 0 3px 8px rgb(0 0 0 / 10%);
}

&:active,
&:focus {
  color: #007FF5;
  box-shadow: 0 0 0 2px #007FF5,
    inset 0 3px 8px rgb(0 0 0 / 10%);
}

&:hover::before,
&:active::before,
&:focus::before {
  opacity: 0;
}
`;

const Link = s.a`
color: #007FF5;
text-decoration: none;
font-family: Manrope;
font-weight: bold;
font-size: 18px;
line-height: 25px;
`;
