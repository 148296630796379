import Link from "next/link";
import s from "styled-components";

import {useSite, useSiteInfo} from "@s/lib/context";
import {transparentize} from "polished";

export default function MinimalFooter() {
  const site = useSite();
  const info = useSiteInfo();

  return (
    <Footer>
      <Title>{site.about.name}</Title>
      <Link href="/" passHref legacyBehavior>
        <HomeLink>{info.host}</HomeLink>
      </Link>
      <PublitLink>Designed with Publit</PublitLink>
    </Footer>
  );
}

const Footer = s.footer`
flex-grow: 1;

display: flex;
flex-direction: column;
align-items: center;
padding: 80px 0;

background: linear-gradient(to bottom, ${props =>
  transparentize(0.85, props.theme.siteTheme.color)}, ${props =>
  transparentize(0.95, props.theme.siteTheme.color)} 22%, ${props =>
  transparentize(1, props.theme.siteTheme.color)})
`;

const Title = s.h1`
margin-bottom: 20px;

font-size: 20px;
`;

const HomeLink = s.a`
color: ${props => props.theme.siteTheme.color};
`;

const PublitLink = s.a.attrs({
  href: "https://publit.app",
  target: "_blank",
})`
display: block;
margin-top: 20px;

color: ${props => props.theme.siteTheme.color};
font-weight: bold;
font-style: italic;
`;
