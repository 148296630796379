import {useState} from "react";
import styled from "styled-components";

import Publication from "./Publication";
import {SiteWorkSectionType} from "publit-shared";

interface Props {
  fullWork?: boolean;
  primaryWorks: SiteWorkSectionType;
  secondaryWorks: SiteWorkSectionType;
}

export default function HomePublication({
  fullWork = false,
  primaryWorks,
  secondaryWorks,
}: Props) {
  const [posTop, setPosTop] = useState(0);

  return (
    <Section>
      <Divider />
      <ContentWrapper>
        <Header>{primaryWorks.title}</Header>
        <Container>
          {!fullWork && <Indicator top={posTop} />}
          {primaryWorks.items.map((item, id) => (
            <Publication
              key={`pub-${id}`}
              articleId={id + 1}
              setIndicator={setPosTop}
              item={item}
            />
          ))}
        </Container>
        {fullWork && (
          <>
            <Header padTop>{secondaryWorks.title}</Header>
            <Container>
              {secondaryWorks.items.map((item, id) => (
                <Publication
                  key={`pub-${id}`}
                  articleId={id + 1}
                  setIndicator={setPosTop}
                  item={item}
                />
              ))}
            </Container>
          </>
        )}
      </ContentWrapper>
    </Section>
  );
}

const Section = styled.section`
  display: flex;
`;

const Divider = styled.div`
  background-color: ${props => props.theme.siteTheme.color};
  flex: 0 0 auto;
  width: 10px;
  @media (min-width: 620px) {
    width: 40px;
  }
  @media (min-width: 768px) {
    width: 80px;
  }
  @media (min-width: 1024px) {
    width: 110px;
  }
  @media (min-width: 1200px) {
    width: 200px;
  }
  @media (min-width: 1440px) {
    width: 320px;
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  padding: 80px 0;
  width: 100%;
`;

const Header = styled.h1<{padTop?: boolean}>`
  font-size: 1.5rem;
  margin-bottom: 40px;
  margin-left: -10px;
  text-align: center;
  text-transform: uppercase;

  margin-top: ${({padTop}) => (padTop ? "40px" : "0px")};

  @media (min-width: 620px) {
    margin-left: -40px;
  }
  @media (min-width: 768px) {
    margin-left: -80px;
  }
  @media (min-width: 1024px) {
    margin-left: -110px;
  }
  @media (min-width: 1200px) {
    margin-left: -200px;
  }
  @media (min-width: 1440px) {
    margin-left: -320px;
  }
`;

const Container = styled.div`
  padding-left: 30px;
  padding-right: 40px;
  position: relative;

  @media (min-width: 620px) {
    padding-left: 50px;
    padding-right: 60px;
  }
  @media (min-width: 1024px) {
    padding-right: 100px;
  }
  @media (min-width: 1200px) {
    padding-right: 190px;
  }
  @media (min-width: 1440px) {
    padding-right: 310px;
  }
`;

const Indicator = styled.i<{top: number}>`
  border-bottom: 8px solid transparent;
  border-left: 10px solid ${props => props.theme.siteTheme.color};
  border-top: 8px solid transparent;
  display: block;
  height: 0;
  left: 0;
  position: absolute;
  transition: top 200ms ease;
  width: 0;
  ${({top}) => `top: ${top + 4}px`};
`;
