import React from "react";
import styled from "styled-components";

import Navigation from "./Navigation";
import {useSite} from "@s/lib/context";

export default function Header() {
  const site = useSite();

  return (
    <header>
      <TitleContainer>
        <SiteTitle>{site.about.name}</SiteTitle>
      </TitleContainer>
      <Navigation />
    </header>
  );
}

const TitleContainer = styled.div`
  display: flex;
  height: 60px;
  @media (min-width: 620px) {
    height: 80px;
  }
  @media (min-width: 768px) {
    height: 100px;
  }
`;

const SiteTitle = styled.h1`
  font-size: 24px;
  margin: auto;
  text-align: center;
  text-transform: uppercase;
`;
