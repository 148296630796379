import styled from "styled-components";

import RichTextDisplay from "@s/components/common/RichTextDisplay";
import {workLinkProps} from "@s/lib/helpers";
import {SiteWorkItemType} from "publit-shared";
import {MouseEventHandler} from "react";

interface Props {
  setIndicator: (offsetTop: number) => void;
  articleId: number;
  item: SiteWorkItemType;
}

export default function Publication({item, articleId, setIndicator}: Props) {
  const {title, titleRichText} = item;

  const handleHover: MouseEventHandler<HTMLAnchorElement> = ({
    currentTarget,
  }) => {
    setIndicator(currentTarget.offsetTop);
  };

  return (
    <LinkTo {...workLinkProps(item)} onMouseEnter={handleHover}>
      <Initial>{articleId}</Initial>
      <Title>
        {titleRichText ? (
          <RichTextDisplay content={titleRichText} multiline={false} />
        ) : (
          title
        )}
      </Title>
    </LinkTo>
  );
}

const LinkTo = styled.a`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const Initial = styled.div`
  background-color: #e9e9e9;
  border-radius: 2px;
  flex: 0 0 24px;
  font-size: 1.125rem;
  font-weight: 600;
  height: 24px;
  line-height: 24px;
  margin-right: 30px;
  text-align: center;
  text-transform: uppercase;
  width: 24px;
`;

const Title = styled.div`
  flex-grow: 1;
`;
