import {useRouter} from "next/router";
import {useMemo} from "react";

import {SiteWorkItemType} from "publit-shared";
import {CurrentPage} from "@s/components/themes/types";

export const workLinkProps = (item: SiteWorkItemType) => {
  if (item.href.trim().length) {
    const href = item.href.startsWith("http")
      ? item.href
      : `http://${item.href}`;
    return {href, rel: "noopener noreferrer", target: "_blank"};
  } else {
    return {};
  }
};

export const usePathname = () => {
  const {pathname} = useRouter();

  // strip _live or _sites prefixes
  return pathname
    .replace(/_live\/\[site\]\/?/, "")
    .replace(/_sites\/\[site\]\/?/, "");
};

export const useCurrentPage = (): CurrentPage => {
  const pathname = usePathname();

  const page: CurrentPage = useMemo(() => {
    if (pathname === "/") return "home";
    else if (pathname === "/about") return "about";
    else if (pathname === "/work") return "work";
    else if (pathname === "/contact") return "contact";

    return "notfound";
  }, [pathname]);

  return page;
};
