import {useState, useEffect, useMemo} from "react";
import styled from "styled-components";

import {useSite} from "@s/lib/context";

interface RetinaPictureSourceProps {
  cropName: string;
  breakdowns?: string[];
  sizes?: string;
  media?: string;
}

interface RetinaPictureProps {
  alt: string;
  imagePlace: "profile" | "contact";
  sources: RetinaPictureSourceProps[];
  fallbackCropName: string;
}

interface SourceURLOptions {
  retinaID: string;
  imageExists: boolean;
  isThumbnail: boolean;
  cropID?: string;
  resolution?: string;
  isLivePreview?: boolean;
}

const urlFor = (place: string, cropName: string, options: SourceURLOptions) => {
  const {
    retinaID,
    imageExists,
    resolution = "1x",
    isThumbnail,
    cropID: possibleCropID,
  } = options;

  const postfix = isThumbnail ? "-thumbnail" : "";
  const path = imageExists ? retinaID : "defaults/renders";

  const folder = possibleCropID ? `${place}/${possibleCropID}` : place;

  return `https://assets.publit.co/${path}/${folder}/${cropName}${postfix}@${resolution}.jpeg`;
};

interface BuildSourceOptions {
  cropID?: string;
  isThumbnail: boolean;
}

const buildSources = (
  sources: RetinaPictureSourceProps[],
  imagePlace: string,
  retinaID: string,
  imageExists: boolean,
  {cropID, isThumbnail}: BuildSourceOptions
) => {
  return sources.map(source => {
    const resolutions = ["1x", "2x", "3x"];
    const breakdowns = source.breakdowns ?? resolutions;

    const srcSet = resolutions
      .map((resolution, idx) =>
        [
          urlFor(imagePlace, source.cropName, {
            retinaID,
            cropID,
            imageExists,
            resolution,
            isThumbnail,
          }),
          breakdowns[idx],
        ].join(" ")
      )
      .join(", ");

    return {
      srcSet,
      sizes: source.sizes,
      media: source.media,
    };
  });
};

const RetinaPicture = ({
  sources,
  alt,
  imagePlace,
  fallbackCropName,
}: RetinaPictureProps) => {
  const {retinaID, imageCrops} = useSite();
  const imageExists = !!imageCrops[imagePlace];
  const cropID = imageCrops[imagePlace]?.id;

  const [error, setError] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => setVisible(true), []);

  const memoizedSources = useMemo(
    () =>
      buildSources(sources, imagePlace, retinaID, imageExists, {
        isThumbnail: false,
        cropID,
      }),
    [sources, imagePlace, retinaID, cropID, imageExists]
  );

  const memoizedThumbnails = useMemo(
    () =>
      buildSources(sources, imagePlace, retinaID, imageExists, {
        isThumbnail: true,
        cropID,
      }),
    [sources, imagePlace, retinaID, cropID, imageExists]
  );

  const onImageLoad = () => setLoaded(true);

  const onThumbnailLoad = () => setError(false);

  const onImageError = () => setError(true);

  if (error) {
    return <Loading>Loading...</Loading>;
  }

  return (
    <ImageContainer>
      <ThumbnailPicture loaded={isLoaded}>
        {memoizedThumbnails.map((s, i) => (
          <source
            key={`thumbnail-${i}`}
            srcSet={s.srcSet}
            media={s.media}
            sizes={s.sizes}
          />
        ))}
        <Image
          src={urlFor(imagePlace, fallbackCropName, {
            retinaID,
            cropID,
            imageExists,
            isThumbnail: true,
          })}
          onLoad={onThumbnailLoad}
          onError={onImageError}
        />
      </ThumbnailPicture>
      {isVisible && (
        <Picture loaded={isLoaded}>
          {memoizedSources.map((s, i) => (
            <source
              key={`source-${i}`}
              srcSet={s.srcSet}
              media={s.media}
              sizes={s.sizes}
            />
          ))}
          <Image
            src={urlFor(imagePlace, fallbackCropName, {
              retinaID,
              cropID,
              imageExists,
              isThumbnail: false,
            })}
            alt={alt}
            onLoad={onImageLoad}
            onError={onImageError}
          />
        </Picture>
      )}
    </ImageContainer>
  );
};

export default RetinaPicture;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const ThumbnailPicture = styled.picture<{loaded: boolean}>`
  position: relative;
  filter: blur(20px);
  opacity: ${props => (props.loaded ? "0" : "1")};
  transition: opacity 0s ease;
  transition-delay: 250ms;
`;

const Picture = styled.picture<{loaded: boolean}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${props => (props.loaded ? "1" : "0")};
  transition: opacity 250ms ease;
`;

const Image = styled.img`
  display: block;
  width: 100%;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
