import HomePublication from "../HomePublication";

import {useSite} from "@s/lib/context";

export default function WorkPage() {
  const site = useSite();

  return (
    <HomePublication
      fullWork
      primaryWorks={site.primaryWorks}
      secondaryWorks={site.secondaryWorks}
    />
  );
}
