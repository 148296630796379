import About from "../About";
import Social from "../Social";
import Work from "../Work";

export default function ModernHome() {
  return (
    <>
      <About introductionOnly />
      <Work hideSecondaryWorks />
      <Social />
    </>
  );
}
