import RetinaPicture from "@s/components/common/Retina";

interface ProfileImageProps {
  small?: boolean;
}

export default function ProfileImage({small}: ProfileImageProps) {
  const cropName = small ? "about" : "home";

  return (
    <RetinaPicture
      alt="Profile Image"
      imagePlace="profile"
      fallbackCropName={cropName}
      sources={[{cropName}]}
    />
  );
}
