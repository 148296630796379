import React from "react";
import styled from "styled-components";

function LinkedIn() {
  return (
    <svg
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <Path
          d="m0 18.5673245c0 .7910314.66168956 1.4326755 1.4779634 1.4326755h17.0440732c.8162469 0 1.4779634-.6416441 1.4779634-1.4326755v-17.13464902c0-.79129866-.6617165-1.43267548-1.4779634-1.43267548h-17.0440732c-.81627384 0-1.4779634.64137682-1.4779634 1.43267548z"
          fill-rule="nonzero"
        />
        <g fill="#fff">
          <path
            d="m6.11505086 3.4127817v9.0605894h-3.01157218v-9.0605894zm-1.5057995 10.2976598c1.05019615 0 1.7038541.6957516 1.7038541 1.5652401-.0195722.8890606-.65365795 1.5655081-1.68393335 1.5655081-1.03016815 0-1.70372004-.6764475-1.70372004-1.5655081 0-.8694885.6535239-1.5652401 1.66417347-1.5652401h.0195722z"
            transform="matrix(1 0 0 -1 0 20.253972)"
          />
          <path
            d="m7.66229417 7.54436065h3.01154533v5.05981665c0 .2707935.0195722.5413188.0990944.734896.2177072.5410507.7132325 1.1014056 1.5451852 1.1014056 1.0897427 0 1.5256666-.8308803 1.5256666-2.0489147v-4.84720355h3.0113041v5.19521335c0 2.7830064-1.4857447 4.0779891-3.4671219 4.0779891-1.6246538 0-2.3378863-.9080966-2.7341564-1.5266318h.0201085v1.3140187h-3.01162583c.0396002-.8501843 0-9.06058935 0-9.06058935z"
            transform="matrix(1 0 0 -1 0 24.361924)"
          />
        </g>
      </g>
    </svg>
  );
}

export default React.memo(LinkedIn);

const Path = styled.path`
  fill: ${props => props.theme.siteTheme.color};
`;
