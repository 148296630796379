import {ReactNode, useMemo} from "react";

import {useTrack} from "lib/analytics/useTrack";
import {SitePageProps} from "@s/lib/types";
import {SiteProvider} from "@s/lib/context";
import styled, {ThemeProvider} from "styled-components";

import {CurrentPage} from "@s/components/themes/types";

import GlobalStyle from "./GlobalStyle";
import SEO from "../SEO";

interface Props extends SitePageProps {
  children: ReactNode;
  page: CurrentPage;
}

export default function Layout({children, data, info, page}: Props) {
  const {theme} = data;

  const {isPreview} = info;

  useTrack({isPreview});

  const title = useMemo(
    () => page.charAt(0).toUpperCase() + page.slice(1),
    [page]
  );

  return (
    <>
      <SEO site={data} title={title} />
      <SiteProvider value={{data, info}}>
        <ThemeProvider theme={{siteTheme: {...theme, isPreview}} as any}>
          <GlobalStyle />
          <Root>
            {isPreview ? (
              <PreviewBanner>
                <p>You are viewing a live preview of your site.</p>
              </PreviewBanner>
            ) : null}
            {children}
          </Root>
        </ThemeProvider>
      </SiteProvider>
    </>
  );
}

const Root = styled.main`
  padding-top: ${props => (props.theme.siteTheme.isPreview ? "40px" : "0")};
`;

const PreviewBanner = styled.div`
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100vw;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;
