import HomeHero from "../HomeHero";
import SocialLinkBanner from "../SocialLinkBanner";
import HomePublication from "../HomePublication";

import {useSite} from "@s/lib/context";

export default function HomePage() {
  const site = useSite();

  return (
    <>
      <HomeHero
        about={site.about}
        retinaID={site.retinaID}
        imageCrops={site.imageCrops}
        biographyRichText={site.biographyRichText}
      />
      <SocialLinkBanner socialLinks={site.socialLinks} />
      <HomePublication
        primaryWorks={site.primaryWorks}
        secondaryWorks={site.secondaryWorks}
      />
    </>
  );
}
