import {SiteWorkSectionType} from "publit-shared";

import {useSite} from "@s/lib/context";
import {workLinkProps} from "@s/lib/helpers";

import RichTextDisplay from "@s/components/common/RichTextDisplay";
import {Paragraph, Section, Title, Link, Index} from "../shared";

function WorkSection({works}: {works: SiteWorkSectionType}) {
  return (
    <Section>
      <Title>{works.title}</Title>

      {works.items.map((item, idx) => (
        <Link key={item.id} {...workLinkProps(item)}>
          <Index>{idx + 1}.</Index>

          {item.titleRichText ? (
            <RichTextDisplay multiline={false} content={item.titleRichText} />
          ) : (
            <Paragraph>{item.title}</Paragraph>
          )}
        </Link>
      ))}
    </Section>
  );
}

export default function MinimalWork() {
  const site = useSite();

  return (
    <>
      <WorkSection works={site.primaryWorks} />
      <WorkSection works={site.secondaryWorks} />
    </>
  );
}
