import s from "styled-components";

import {Template, TemplateLayoutProps} from "../types";

import Footer from "./Footer";
import Header from "./Header";
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import WorkPage from "./WorkPage";
import ContactPage from "./ContactPage";

import NotFound from "@s/components/common/NotFound";

const Layout = ({children}: TemplateLayoutProps) => (
  <Container>
    <Header />
    {children}
    <Footer />
  </Container>
);

const Container = s.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1440px;
  min-height: 100vh;
`;

const modern: Template = {
  Layout,
  pages: {
    Home: HomePage,
    About: AboutPage,
    Work: WorkPage,
    Contact: ContactPage,
    NotFound,
  },
};

export default modern;
