import s from "styled-components";

import ContactHero from "../ContactHero";
import ContactForm from "../ContactForm";
import SocialLinkBanner from "../SocialLinkBanner";

import {useSite} from "@s/lib/context";

export default function ContactPage() {
  const site = useSite();

  return (
    <>
      <ContactSection>
        <ContactHero />
        <ContactForm
          hideEmail={site.about.hideEmail}
          email={site.about.email}
        />
      </ContactSection>
      <SocialLinkBanner socialLinks={site.socialLinks} />
    </>
  );
}

const ContactSection = s.section`
  @media (min-width: 1024px) {
    display: flex;
  }
`;
