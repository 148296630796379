import {ReactNode, useMemo, useState} from "react";

import {useSite} from "@s/lib/context";

import RichTextDisplay from "@s/components/common/RichTextDisplay";
import {Section, Title, Paragraph, Button, Link} from "../shared";

function AdditionalInfo() {
  const site = useSite();

  const resumeLink =
    site.resume &&
    `https://assets.publit.co/${site.retinaID}/resume/${site.resume.uploadID}/${site.resume.name}`;

  return (
    <>
      <Section smallBottomPadding>
        <Title>Education</Title>
        {site.education.map(item => (
          <Paragraph key={item.id}>{item.title}</Paragraph>
        ))}
      </Section>
      {site.resume && resumeLink && (
        <Section>
          <Title>{site.resume.sectionTitle}</Title>
          <Paragraph>
            <Link href={resumeLink} target="_blank">
              {site.resume.name}
            </Link>
          </Paragraph>
        </Section>
      )}
    </>
  );
}

interface Props {
  introductionOnly?: boolean;
  children?: ReactNode; // need this to be compatible with ComponentType
}

export default function ModernAbout({introductionOnly = false}: Props) {
  const site = useSite();
  const [isExpanded, setExpanded] = useState(!introductionOnly);

  const hasRichTextBiography = !!site.biographyRichText;

  const biographyText = useMemo(() => {
    if (!site.biography) return [];
    return site.biography.split("\n").filter(Boolean);
  }, [site.biography]);

  return (
    <>
      <Section smallBottomPadding={!introductionOnly}>
        <Title>About</Title>
        {hasRichTextBiography ? (
          <RichTextDisplay
            content={site.biographyRichText}
            multiline={isExpanded}
            alwaysIncludePadding={true}
          />
        ) : (
          biographyText
            .slice(0, isExpanded ? site.biography?.length : 1)
            .map((content, id) => (
              <Paragraph key={`bio-${id}`}>{content}</Paragraph>
            ))
        )}
        {introductionOnly && (
          <Button onClick={() => setExpanded(e => !e)}>
            Read {isExpanded ? "Less" : "More"}
          </Button>
        )}
      </Section>
      {!introductionOnly && <AdditionalInfo />}
    </>
  );
}
