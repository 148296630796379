import {ReactNode} from "react";
import s from "styled-components";

export default function BoxComponent({children}: {children: ReactNode}) {
  return (
    <Container>
      <Box>{children}</Box>
    </Container>
  );
}

const Container = s.div`
flex-grow: 1;
height: 100%;
width: 100%;

display: flex;
justify-content: center;
align-items: center;
text-align: center;
padding: 10px 20px 50px;
box-sizing: border-box;

@media (max-width: calc(400px + 40px)) {

}
`;

const Box = s.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
max-width: 540px;
width: 100%;
margin-bottom: 60px;
padding: 40px;
box-sizing: border-box;

background: #fff;
border-radius: 20px;
transition: box-shadow 0.1s ease;
box-shadow: 0px 2px 4px rgb(0 0 0 / 5%),
  0 2px 4px rgb(0 0 0 / 5%),
  0 4px 8px rgb(0 0 0 / 5%),
  0 8px 16px rgb(0 0 0 / 5%),
  0 16px 32px rgb(0 0 0 / 5%);

@media (max-width: calc(400px + 40px)) {
  padding: 40px 20px 20px;
}
`;
