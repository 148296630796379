import s from "styled-components";

export default function NotFound() {
  return (
    <Container>
      <Text>Sorry! The page you are looking for does not exist.</Text>
    </Container>
  );
}

const Container = s.div`
display: flex;
justify-content: center;
align-items: center;
padding: 40px;
min-height: 50vh;
`;

const Text = s.h2``;
