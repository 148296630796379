import React, {useState, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {lighten} from "polished";

import {useSiteInfo} from "@s/lib/context";

const defaultError = {name: false, email: false, message: false};

function ContactForm({email, hideEmail}) {
  const siteInfo = useSiteInfo();

  const formRef = useRef();
  const [buttonLabel, setButtonLabel] = useState("send message");
  const [error, setError] = useState(defaultError);
  const [loading, setLoading] = useState(false);

  const sendEmail = async event => {
    event.preventDefault();
    setLoading(true);
    setError(defaultError);

    if (siteInfo.isPreview) {
      setError({
        server: "Disabled during live preview.",
      });
      setLoading(false);
      return;
    }

    const data = new FormData(event.target);
    const req = await fetch("/api/ms/contact-me", {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: data.get("name"),
        email: data.get("email"),
        company: data.get("company"),
        body: data.get("message"),
      }),
    });

    const res = await req.json();

    setLoading(false);
    if (res.success) {
      formRef.current.reset();
      setButtonLabel("thank you!");
    } else {
      setError(e => ({...e, server: res.error.message}));
    }
  };

  return (
    <Container>
      <Title>contact me</Title>
      <Form
        ref={formRef}
        onInvalid={e => e.preventDefault()}
        onSubmit={sendEmail}
      >
        <InputWrapper>
          <Input
            type="text"
            name="name"
            placeholder="Name"
            required
            error={error.name}
            onInvalid={() => setError({...error, name: true})}
          />
          <Input
            type="email"
            name="email"
            placeholder="Email"
            required
            error={error.email}
            onInvalid={() => setError({...error, email: true})}
          />
          <Input
            type="text"
            name="company"
            placeholder="Company, Institution, or Organization"
          />
          <Input
            type="text"
            name="message"
            placeholder="Message or Questions"
            required
            error={error.message}
            onInvalid={() => setError({...error, message: true})}
          />
          <ErrorMessage
            error={error.name || error.email || error.message || error.server}
          >
            {error.server ? error.server : "Information Required"}
          </ErrorMessage>
        </InputWrapper>
        <Submit disabled={loading}>{buttonLabel}</Submit>
      </Form>
      {!hideEmail && email && email.trim().length > 0 && (
        <p>
          or email me at <a href={`mailto:${email}`}>{email}</a>
        </p>
      )}
    </Container>
  );
}

ContactForm.propTypes = {
  email: PropTypes.string,
  hideEmail: PropTypes.bool.isRequired,
};

export default ContactForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  text-align: center;
  justify-content: center;
  @media (min-width: 1024px) {
    flex-basis: 50%;
  }
`;

const Title = styled.h2`
  color: ${props => props.theme.siteTheme.color};
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
`;

const Form = styled.form`
  margin: 0 auto 40px auto;
  max-width: 400px;
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-bottom: 20px;
  padding-bottom: 1rem;
  position: relative;
`;

const Input = styled.input`
  appearance: none;
  border-bottom: solid 1px #000;
  border-left: none;
  border-radius: 0;
  border-right: none;
  border-top: none;
  font-size: 1.125rem;
  outline: none;
  padding: 12px 10px;
  width: 100%;
  margin-bottom: 10px;

  &::placeholder {
    color: ${({error}) => (error ? "#ff0000" : "#000")};
    font-size: 1.125rem;
    font-weight: 300;
  }
`;

const ErrorMessage = styled.p`
  bottom: 0;
  color: #ff0000;
  font-size: 0.875rem;
  font-weight: 300;
  left: 50%;
  opacity: ${({error}) => (error ? 1 : 0)};
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
`;

const Submit = styled.button`
  position: relative;
  top: 0;
  align-items: center;
  appearance: none;
  background-color: ${props => lighten(0.1, props.theme.siteTheme.color)};
  border-radius: 4px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  height: 50px;
  justify-content: center;
  margin: 0 auto;
  outline: none;
  text-transform: uppercase;
  transition: all 200ms ease;
  width: 280px;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1);

  &:disabled {
    cursor: wait;
    pointer-events: none;
  }

  &:hover {
    top: -2px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    background-color: ${props => props.theme.siteTheme.color};
  }
`;
