import s from "styled-components";

import Logo from "./logo";

const MarketingHeader = () => (
  <Header>
    <Logo />
    <Tagline>Personal Sites for Professionals</Tagline>
  </Header>
);

export default MarketingHeader;

const Header = s.header`
position: relative;
padding: 3.5rem;
color: #000;
box-sizing: border-box;
`;

const Tagline = s.h2`
margin: 19px 0 0;
font-family: Manrope;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;
`;
