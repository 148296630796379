import s from "styled-components";

import {useSite} from "@s/lib/context";

import {Section, Title} from "../shared";
import {Facebook, Instagram, LinkedIn, Twitter, Youtube} from "./icons";
import {darken} from "polished";

export default function ModernSocial() {
  const site = useSite();

  return (
    <Section>
      <Title>Social</Title>
      <Items>
        {site.socialLinks.facebook &&
          site.socialLinks.facebook.trim().length > 0 && (
            <Link href={site.socialLinks.facebook}>
              <Facebook />
            </Link>
          )}
        {site.socialLinks.twitter &&
          site.socialLinks.twitter.trim().length > 0 && (
            <Link href={site.socialLinks.twitter}>
              <Twitter />
            </Link>
          )}
        {site.socialLinks.linkedIn &&
          site.socialLinks.linkedIn.trim().length > 0 && (
            <Link href={site.socialLinks.linkedIn}>
              <LinkedIn />
            </Link>
          )}
        {site.socialLinks.instagram &&
          site.socialLinks.instagram.trim().length > 0 && (
            <Link href={site.socialLinks.instagram}>
              <Instagram />
            </Link>
          )}
        {site.socialLinks.youtube &&
          site.socialLinks.youtube.trim().length > 0 && (
            <Link href={site.socialLinks.youtube}>
              <Youtube />
            </Link>
          )}
      </Items>
    </Section>
  );
}

const Items = s.div`
display: flex;
margin-top: 20px;
`;

const Link = s.a.attrs({
  rel: "noopener noreferrer",
  target: "_blank",
})`
display: block;
color: ${props => props.theme.siteTheme.color};

&:not(:first-child) {
  margin-left: 20px;
}

&:hover {
  color: ${props => darken(0.2, props.theme.siteTheme.color)};
}
`;
