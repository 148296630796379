import React from "react";
import PropTypes from "prop-types";
import Head from "next/head";

function SEO({title, site}) {
  const f = `https://assets.publit.co/favicons`;
  const {color} = site.theme;
  const u = `https://assets.publit.co/${site.retinaID}/contact`;
  const meta = {
    title: site.about.name,
    description: `Website of ${site.about.name}, ${site.about.position}${
      site.about.institution ? ` at ${site.about.institution}` : ""
    }. This site is powered by Publit.`,
    siteImageURL: `${u}/wide@3x.jpeg`,
  };

  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />

      <title>{`${title} | ${meta.title}`}</title>
      <meta name="description" content={meta.description} />
      <meta name="og:image" content={meta.siteImageURL} />
      <meta name="og:title" content={`${title} | ${meta.title}`} />
      <meta name="og:description" content={meta.description} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="publit.app" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={meta.description} />

      <meta name="theme-color" content={color} />
      <link rel="mask-icon" href={`${f}/mask.svg`} color={color} />
      <link
        rel="icon"
        type="image/png"
        href={`${f}/favicon-${color.substr(1)}.png`}
      />
      <link
        rel="icon"
        type="image/svg+xml"
        href={`${f}/favicon-${color.substr(1)}.svg`}
      />
      <link
        rel="apple-touch-icon"
        href={`${f}/apple-touch-icon-${color.substr(1)}.png`}
      />
    </Head>
  );
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
};

export default SEO;
