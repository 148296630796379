import {useSite} from "@s/lib/context";
import {Paragraph, Section, Title} from "../shared";

export default function MinimalEducation() {
  const site = useSite();

  return (
    <Section>
      <Title>Education</Title>
      {site.education.map(item => (
        <Paragraph key={item.id}>{item.title}</Paragraph>
      ))}
    </Section>
  );
}
