import styled from "styled-components";

import RetinaPicture from "@s/components/common/Retina";

export default function HeroImage() {
  return (
    <Image>
      <RetinaPicture
        alt="Contact Image"
        imagePlace="contact"
        fallbackCropName="tall"
        sources={[
          {
            breakdowns: ["720w", "1440w", "2160w"],
            cropName: "tall",
            media: "(min-width: 1024px)",
            sizes: "(min-width: 1440px) 720px, 50vw",
          },
          {
            cropName: "wide",
            breakdowns: ["1024w", "2048w", "3072w"],
            sizes: "100vw",
          },
        ]}
      />
    </Image>
  );
}

const Image = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    width: 50%;
  }
`;
