import Link from "next/link";
import s from "styled-components";

import {Section} from "../shared";

export default function ModernHeader() {
  return (
    <>
      <Header>
        <Navigation>
          <Link href="/" passHref legacyBehavior>
            <NavLink>Home</NavLink>
          </Link>
          <Link href="/about" passHref legacyBehavior>
            <NavLink>About</NavLink>
          </Link>
          <Link href="/work" passHref legacyBehavior>
            <NavLink>Work</NavLink>
          </Link>
          <Link href="/contact" passHref legacyBehavior>
            <NavLink>Contact</NavLink>
          </Link>
        </Navigation>
      </Header>
    </>
  );
}

const Header = s(Section)`
display: flex;
justify-content: center;
padding: 0;

@media (max-width: 540px) {
  margin: 0;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
}
`;

const Navigation = s.nav`
display: flex;
align-items: stretch;
`;

const NavLink = s.a`
display: block;
padding: 20px;
margin: -1px 0;

border-bottom: 1px solid transparent;
font-weight: 600;
color: ${props => props.theme.siteTheme.color};

&:hover {
  border-bottom: 1px solid;
}
`;
